import React from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import classNames from "classnames";
import { Quill } from "quill";

import "react-quill/dist/quill.snow.css";

type HandlerInstanceType = { quill: Quill & { theme: any } };

/**
 * Custom icons for the toolbar
 * Adding new icons that not not exists in the default toolbar need to be
 * added as a string with the svg content. We haven't found a better way
 * to do it using JSX syntax.
 *
 * @TODO: Maybe we should move it to a isolated file if it starts to grow
 * */
const customToolbarIcons = {
  undo: `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
  <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`,

  redo: `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
  <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`,
};

const ReactQuillComponent =
  typeof window === "object" ? require("react-quill") : () => false;

typeof window === "object"
  ? (() => {
      /* eslint @typescript-eslint/no-var-requires: "off" */
      ReactQuillComponent.Quill.register(
        "modules/magicUrl",
        require("quill-magic-url").default
      );
      const Link = ReactQuillComponent.Quill.import("formats/link");

      class HttpDefaultLink extends Link {
        static sanitize(url: string) {
          const sanitizedUrl = super.sanitize(url);
          if (!sanitizedUrl || sanitizedUrl === "about:blank")
            return sanitizedUrl;
          const hasWhitelistedProtocol = [
            "http",
            "https",
            "mailto",
            "tel",
          ].some((protocol) => {
            return sanitizedUrl.startsWith(protocol);
          });

          if (hasWhitelistedProtocol) return sanitizedUrl;
          return `https://${sanitizedUrl}`;
        }
      }

      // adding undo and redo icons
      const icons = ReactQuillComponent.Quill.import("ui/icons");
      icons["undo"] = customToolbarIcons.undo;
      icons["redo"] = customToolbarIcons.redo;

      ReactQuillComponent.Quill.register(HttpDefaultLink, true);

      // changing the default block tag to improve gmail received message from Practice
      const Block = ReactQuillComponent.Quill.import("blots/block");
      Block.tagName = "div";
      ReactQuillComponent.Quill.register(Block);
    })()
  : () => false;

// @TODO: Maybe we should move it to a isolated file
//        - it allow the user to edit the link. It is not the best experience
//          but at least works ultil the library have the same behaivior
//          of the snow theme:
//          https://github.com/quilljs/quill/issues/1380
const handlerLink = function (this: HandlerInstanceType, value: boolean) {
  if (value) {
    this.quill.theme.tooltip.edit();
  } else {
    this.quill.theme.tooltip.edit("link", this.quill.getFormat().link);
  }
};

const magicUrlConfig = {
  normalizeUrlOptions: {
    stripHash: true,
    stripWWW: true,
    normalizeProtocol: true,
  },
};

const defaultEditorProps: ReactQuillProps["modules"] = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      ["undo", "redo"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
    handlers: {
      link: handlerLink,
      undo: function (this: HandlerInstanceType) {
        this.quill.history.undo();
      },
      redo: function (this: HandlerInstanceType) {
        this.quill.history.redo();
      },
    },
  },
  magicUrl: magicUrlConfig,
  history: {
    userOnly: true,
  },
};

const MODULES_CONFIG = {
  note: defaultEditorProps,
  email: defaultEditorProps,
};

type Props = {
  children?: React.ReactNode;
  kind: "email" | "note";
  className?: string;
} & ReactQuill.ReactQuillProps;
export type Ref = ReactQuill;
export const Editor = React.forwardRef<Ref, Props>(
  ({ children, kind, className, readOnly, ...rest }, ref) => (
    <ReactQuillComponent
      className={classNames(className, readOnly && "read-only")}
      modules={MODULES_CONFIG[kind]}
      theme="snow"
      bounds="main"
      scrollingContainer="main"
      ref={ref}
      readOnly={readOnly}
      {...rest}
    />
  )
);
