import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

const ClientStatusEnum = z.enum(["archived", "active", "merged", "deleted"]);
export type ClientStatusEnumType = z.infer<typeof ClientStatusEnum>;

const ClientTypeEnum = z.enum(["individual", "company", "family"]);
export type ClientTypeEnumType = z.infer<typeof ClientTypeEnum>;

const CustomFieldSchema = z.object({
  value: z.any(),
  field: z.string(),
  formId: z.string().optional(),
});

const FeedItemSchema = z.object({
  id: z.string().optional(),
  actionId: z.string(),
  clientId: z.string(),
  createdAt: z.date(),
  modifierId: z.string(),
  name: z.string(),
  resourceId: z.string(),
  type: z.enum([
    "task",
    "appointment",
    "form",
    "invoice",
    "file",
    "link",
    "thread",
    "note",
    "folder",
  ]),

  // @TODO: fix type
  diff: z.any().optional(),
});

export const ClientSchema = BaseSchema.extend({
  firstName: z.string().optional(),
  address: z
    .object({
      name: z.string().optional(),
      email: z.string().email().or(z.string()).optional(),
      city: z.string().optional(),
      country: z.string().optional(),
      line1: z.string().optional(),
      postalCode: z.string().optional(),
      state: z.string().optional(),
    })
    .optional(),
  coachUserId: z.string(),
  color: z
    .object({
      foreground: z.string(),
      background: z.string(),
    })
    .optional(),
  company: z.string().optional(),
  archivedAt: z.date().nullable().optional(),
  email: z.string(),
  emails: z.array(z.string()).optional(),
  lastName: z.string().optional(),
  latestActivity: FeedItemSchema.optional(),
  phoneNumber: z.string().optional(),
  status: ClientStatusEnum.optional(),
  stream: z.any().optional(),
  customFields: z.array(CustomFieldSchema).optional(),
  timeZone: z.string().optional(),
  clientType: ClientTypeEnum,
  members: z.array(z.object({ id: z.string(), joined: z.date() })).optional(),
  memberIds: z.array(z.string()).optional(),
  labels: z.array(z.string()).optional(),
  stripe: z.any().optional(),
  tasks: z
    .object({
      taskIds: z.string().array().optional(),
      title: z.string().optional(),
    })
    .optional(),
  avatarURL: z.string().nullable().optional(),
  // @TODO: check if we really need this prop. It is currently used in the chat pages
  userId: z.string().optional(),
  // Families and Companies don't have just one account associated
  accountId: z.string().nullable().optional(),
  assigneeId: z.string().optional(),
  assigneeIds: z.array(z.string()).optional(),
  billingContactId: z.string().optional(),
  primaryContactId: z.string().optional(),
  portalJoinedAt: z.date().optional(),
});

export type ClientType = z.infer<typeof ClientSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "clients",
  deleteMode: "none",
};
